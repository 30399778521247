import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { IconContext } from 'react-icons';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import GlobalColors from "../../../config/global-colors";
import { AuthContext } from "../../../contexts/AuthContext";
import {
    ListBtn, MenuDesc, Name, NavBar, NavContent, Role, UserIcon, UserInfos, CustomDropdownMenu
} from './styles';
import * as FiIcons from 'react-icons/fi';
import * as RiIcons from 'react-icons/ri';

interface NavbarProps {
    isSidebarOpen: boolean;
    setIsSidebarOpen(value: boolean): void;
}


const Navbar: React.FC<NavbarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const { t } = useTranslation();
    const { logout, userName, userRole } = useContext(AuthContext);
    const history = useHistory();
    
    const toInitials = (name: string = "") => {
        const match = name ? name.match(/(^\S\S?|\b\S)?/g) : "";
        if (match) {
            const subMatch =  match.join("").match(/(^\S|\S$)?/g);
            if (subMatch) {
                return subMatch.join("").toUpperCase();
            }
        }
        return "AA";
    }
    
    const getFirstName = (name: string = "") => {
        const firstName = name ? name.split(" ")[0] : "";
        return firstName;
    }


    return (
        <IconContext.Provider value={{ color: `${GlobalColors.primary}`, size: '1.5rem' }}>
            <NavBar>
                <NavContent>
                    <ListBtn onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        <AiIcons.AiOutlineUnorderedList />
                    </ListBtn>
                    <UncontrolledDropdown>
                        <DropdownToggle
                            tag="span"
                            data-toggle="dropdown"
                        >
                            <UserIcon>{toInitials(userName)}</UserIcon>
                        </DropdownToggle>
                        <CustomDropdownMenu className='drop-down'>
                            <DropdownItem header className='drop-down-header'>
                                <UserIcon>{toInitials(userName)}</UserIcon>
                                <UserInfos>
                                    <Name>{getFirstName(userName)}</Name>
                                    <Role>{userRole}</Role>
                                </UserInfos>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem className='drop-down-item' onClick={() => history.push({pathname: '/edit-profile'})}>
                                <RiIcons.RiEdit2Fill />
                                <MenuDesc>{t("edit_profile")}</MenuDesc>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem className='drop-down-item' onClick={() => logout()}>
                                <FiIcons.FiLogOut />
                                <MenuDesc>{t("logout")}</MenuDesc>
                            </DropdownItem>
                        </CustomDropdownMenu>
                    </UncontrolledDropdown>
                </NavContent>
            </NavBar>
        </IconContext.Provider>
    );
};

export default Navbar;