import axios from "axios";
import { History } from "history";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(async (config) => {
  // config.params['h'] = Number(new Date());
  const token = localStorage.getItem("@conference/token");
  const newConfig = config;
  if (token && newConfig.headers) {
    newConfig.headers.Authorization = `Bearer ${treateToken(token)}`;
  }
  const timestamp = new Date().getTime();
  newConfig.params = {...newConfig.params, h: timestamp}
  return newConfig;
});

export const setupResponseInterceptor = (history: History) => {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        history.push("/");
      }
      return Promise.reject(error);
    },
  );
};

function treateToken(token: string) {
  return token.replace(/['"]+/g, "");
}

export default api;
