import { createContext, useContext, useState, ReactNode } from "react";

interface ModalOptions {
    title: string;
    btnCancelLabel?: string;
    btnOkLabel?: string;
    shouldNotHideAferConfirm?: boolean;
    onConfirmButton?: () => void;
}

interface ModalContextValue {
    isOpen: boolean;
    isModalLoading: boolean;
    modalContent: ReactNode;
    modalOptions: ModalOptions;
    showModal: (content: ReactNode, options?: ModalOptions) => void;
    hideModal: () => void;
    setIsModalLoading: (options: boolean) => void;
    setModalOptions: (options: ModalOptions) => void;
}

const ModalContext = createContext<ModalContextValue>({} as ModalContextValue);


export const useModal = () => useContext(ModalContext);

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ReactNode>(null);
    const [modalOptions, setModalOptions] = useState<ModalOptions>({ title: "" });
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

    const showModal = (content: ReactNode, options?: ModalOptions) => {
        setIsOpen(true);
        setModalContent(content);
        if (options) {
            setModalOptions(options);
        }
    };

    const hideModal = () => {
        setIsOpen(false);
        setModalContent(null);
    };

    return (
        <ModalContext.Provider
            value={{
                isOpen,
                modalContent,
                modalOptions,
                isModalLoading,
                showModal,
                hideModal,
                setModalOptions,
                setIsModalLoading,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};
