import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { useSpring } from "react-spring";
import Sidebar from './sidebar';
import Navbar from './navbar'
import {
    DashBoardStyle,
    Content,
    ContentCard,
} from './styles';

const Dashboard: React.FC = ({children}) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
    return (
        <DashBoardStyle>
            <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
            <Content className={isSidebarOpen ? 'ml2' : 'ml1'}>
                <Navbar  isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
                <ContentCard>
                    {children}
                </ContentCard>
            </Content>
        </DashBoardStyle>
    );
};

export default Dashboard;