import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import usePersistedState from "../hooks/usePersistantState";
import { checkApiToken } from "../services/auth.service";

interface AuthProps {
  token: string;
}

interface AuthContextProps extends AuthProps {
  login(payload: AuthProps): void;
  logout: () => void;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  allowReceiveEmail: boolean;
  checkToken: () => Promise<void>;
  setUser: (id: number) => void;
  userId: number | null;
  userRole: string | null;
  userName: string;
  setRole(role: string): void;
  setName(name: string): void;
  emailToRegister: string;
  setEmailToRegister(email: string): void;
  setAllowReceiveEmail(value: boolean): void;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = usePersistedState<string>("@conference/token", "");
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [allowReceiveEmail, setAllowReceiveEmail] = useState(true);
  const [userRole, setUserRole] = useState<any>(null);
  const [userId, setUserId] = usePersistedState<any>("@user/id", null);
  const [userName, setUserName] = usePersistedState<any>("@user/name", null);
  const [emailToRegister, setEmailToRegister] = useState<string>("");

  const checkToken = async () => {
    setIsAuthenticating(true);
    try {
      const response = await checkApiToken(token);

      if (response.data) {
        setIsAuthenticated(true);
      }
    } catch (e) {
      const error = "Autenticação necessária";
      toast.error(error);
      setIsAuthenticated(false);
    }

    setIsAuthenticating(false);
  };

  const login = (payload: AuthProps) => {
    setToken(payload.token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    console.log('Logout')
    setToken("");
    setIsAuthenticated(false);
  };

  const setUser = (id: number) => {
    setUserId(id);
  };

  const setRole = (role: string) => {
    setUserRole(role);
  };

  const setName = (name: string) => {
    setUserName(name);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        isAuthenticated,
        isAuthenticating,
        allowReceiveEmail,
        checkToken,
        setUser,
        userId,
        setRole,
        userRole,
        userName,
        setName,
        emailToRegister,
        setEmailToRegister,
        setAllowReceiveEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
