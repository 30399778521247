import api from "./api";

export const checkApiToken = (token: string) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  return api.get(`/auth/check-token`, {
    headers,
  });
};
