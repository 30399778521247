import styled from "styled-components";

export const DashBoardStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #F8F8F8;
  .ml1 {
    left: 6rem;
  }
  .ml2 {
    left: 17rem;
  }
  `;

export const Content = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    @media (max-width: 575px) {
      left: 1rem !important ; 
    }
`;

export const ContentCard = styled.div `
  height: 100%;
  width: 100%;
  padding: 1rem 0rem 0rem 0rem;
`;