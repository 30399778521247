import { DropdownMenu } from "reactstrap";
import styled from "styled-components";

export const NavBar = styled.div`
  background-color: white;
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 0rem 1rem;
`;

export const NavContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .drop-down {
    top: -23px !important;
    right: -27px !important;
    width: 249px !important;
  }
  .drop-down-header {
    display: flex !important;
    align-items: center !important;
  }
  .drop-down-item {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
`;

export const CustomDropdownMenu = styled(DropdownMenu)`
  /* left: -190px !important; */
`;

export const ListBtn = styled.div`
  width: 33px;
  height: 31px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Name = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #204f63;
  margin-left: 0.8rem;
`;

export const UserInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuDesc = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #204f63;
  width: 100%;
  text-align: center;
`;

export const Role = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #99a2a6;
  margin-left: 0.8rem;
`;

export const UserIcon = styled.div`
  width: 40px;
  height: 40px;
  background: #204f63;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  /* color: #204F63; */
  color: white;
`;

export const DropDownContent = styled.div`
  .settings-icon {
    margin-left: 1.5rem;
    transition: transform 0.2s ease-in-out 0.1s;
    :hover{
      transform: rotate(360deg);
      cursor: pointer;
    }
  }
`;