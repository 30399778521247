import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import * as FiIcons from 'react-icons/fi';
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';

export const SidebarData = [
  {
    title: 'users',
    path: '/users',
    icon: <FiIcons.FiUsers />,
    cName: 'users'
  },
  {
    title: 'conferences',
    path: '/conferences',
    icon: <AiIcons.AiOutlineCalendar />,
    cName: 'conferences'
  },
  {
    title: 'export',
    path: '/exports',
    icon: <BsIcons.BsClouds />,
    cName: 'export'
  },
  {
    title: 'live',
    path: '/live',
    icon: <RiIcons.RiLiveLine />,
    cName: 'Live'
  },
  {
    title: 'groups',
    path: '/groups',
    icon: <MdIcons.MdGroups/>,
    cName: 'groups'
  },
  {
    title: 'email',
    path: '/email',
    icon: <HiIcons.HiOutlineMail />,
    cName: 'email'
  },
  {
    title: 'user-selection',
    path: '/user-selection',
    icon: <BsIcons.BsClipboardData/>,
    cName: 'user-selection'
  }
];
