const GlobalColors = {
    primary:'#204F63',
    secondary: '#0098A6',
    clean: '#BFDACB',
    warm: '#DC6E61',
    // danger: '#EA1F25' ,
    danger: '#E95151' ,
    dark: '#3C3C3E'
};

export default GlobalColors;
