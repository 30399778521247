import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = ({...rest }:any) => {
  return (
    <div>
      <CircularProgress size="1.8rem" color="primary" {...rest} />
    </div>
  );
};

export default Loading;