import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "../components/loader";
import { AuthContext } from "../contexts/AuthContext";
import DashBoard from "../pages/dashBoard";
import jwt from "jsonwebtoken";
import GenericModal from "../components/GenericModal";

const login = lazy(() => import("../pages/login/Login"));
// const live = lazy(() =>
//   import("../pages/live/Live")
// );
const dashBoard = lazy(() => import("../pages/dashBoard"));
const register = lazy(() => import("../pages/register/Register"));
const changePassword = lazy(() => import("../pages/changePassword/ChangePassword"));
const passwordRecover = lazy(() => import("../pages/passwordRecorver/PasswordRecover"));
const forgotPassword = lazy(() => import("../pages/forgotPassword/ForgotPassword"));
const conferences = lazy(() => import("../pages/conferences"));
const users = lazy(() => import("../pages/users"));
const exports = lazy(() => import("../pages/exports"));
const home = lazy(() => import("../pages/home"));
const activeAccount = lazy(() => import("../pages/activeAccount"));
const editProfile = lazy(() => import("../pages/userEditForm"));
const liveEditProfile = lazy(() => import("../pages/liveProfileEdit"));
const groups = lazy(() => import("../pages/groups"));
const email = lazy(() => import("../pages/email"));
const findUsers = lazy(() => import("../pages/findUsers"));
const unsubscribe = lazy(() => import("../pages/unsubscribe"));

const live = lazy(() => import("../pages/live/Live"));

interface AppRouteProps {
  layout: "full" | "vertical";
  exact: boolean;
  path: string;
  component: React.ComponentType;
  roles: string[];
}

const AppRoute = ({ layout, component: Component, ...rest }: AppRouteProps) => {
  if (layout === "vertical") {
    return (
      <Route {...rest}>
        <DashBoard>
          <GenericModal />
          <Suspense fallback={<></>}>
            <Component />
          </Suspense>
        </DashBoard>
      </Route>
    );
  } else {
    return (
      <Route {...rest}>
        <Suspense fallback={<></>}>
          <Component />
        </Suspense>
      </Route>
    );
  }
};

const PrivateRoute = ({ layout, component: Component, roles, ...rest }: AppRouteProps) => {
  const { isAuthenticated, isAuthenticating, checkToken, userRole } = useContext(AuthContext);

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasPermission = (role: string | null) =>
    roles.length === 0 || (roles.length > 0 && roles.includes(String(role)));

  if (isAuthenticated) {
    return (
      <Route {...rest}>
        {hasPermission(userRole) ? (
          <>
            {layout === "vertical" ? (
              <DashBoard>
                <GenericModal />
                <Suspense fallback={<></>}>
                  <Component />
                </Suspense>
              </DashBoard>
            ) : (
              <Suspense fallback={<></>}>
                <Component />
              </Suspense>
            )}
          </>
        ) : (
          <Redirect to={{ pathname: "/live" }} />
        )}
      </Route>
    );
  } else if (isAuthenticating) {
    return <Loading />;
  } else {
    return <Redirect to={{ pathname: "/" }} />;
  }
};

const Routes = () => {

  const { userRole, setRole, setName, token } = useContext(AuthContext);

  useEffect(() => {
    if (!userRole && (token && token !== '')) {
      const { role, name } = jwt.decode(token) as any;
      setRole(role);
      setName(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <PrivateRoute layout={"vertical"} roles={["admin"]} exact path="/home" component={home} />
      <PrivateRoute layout={"vertical"} roles={["admin"]} exact path="/exports" component={exports} />
      <PrivateRoute layout={"vertical"} roles={["admin"]} exact path="/conferences" component={conferences} />
      <PrivateRoute layout={"vertical"} roles={["admin"]} exact path="/users" component={users} />
      <PrivateRoute layout={"vertical"} roles={["admin"]} exact path="/groups" component={groups} />
      <PrivateRoute layout={"vertical"} roles={["admin"]} exact path="/email" component={email} />
      <PrivateRoute layout={"full"} roles={["admin"]} exact path="/dashboard" component={dashBoard} />
      <PrivateRoute layout={"vertical"} roles={["admin"]} exact path="/edit-profile" component={editProfile} />
      <PrivateRoute layout={"vertical"} roles={["admin"]} exact path="/user-selection" component={findUsers} />
      <AppRoute layout={"full"} roles={[]} exact path="/" component={login} />
      <PrivateRoute
        layout={"full"}
        roles={["admin", "member"]}
        exact
        path="/change-password"
        component={changePassword}
      />
      <AppRoute
        layout={"full"}
        roles={["admin", "member"]}
        exact
        path="/password-recover"
        component={passwordRecover}
      />
      <AppRoute layout={"full"} roles={[]} exact path="/forgot-password" component={forgotPassword} />
      <PrivateRoute layout={"full"} exact roles={["admin", "member"]} path="/live" component={live} />
      <PrivateRoute
        layout={"full"}
        roles={["admin", "member"]}
        exact
        path="/edit-profile-user"
        component={editProfile}
      />
      <PrivateRoute
        layout={"full"}
        roles={["admin", "member"]}
        exact
        path="/live/edit-profile-user"
        component={liveEditProfile}
      />
      <AppRoute layout={"full"} roles={[]} exact path="/register" component={register} />
      <AppRoute layout={"full"} roles={[]} exact path="/registration-complete" component={register} />
      <AppRoute layout={"full"} roles={[]} exact path="/active-account" component={activeAccount} />
      <AppRoute layout={"full"} roles={[]} exact path="/unsubscribe" component={unsubscribe} />
      <Route>404</Route>
    </Switch>
  );
};

export default Routes;
