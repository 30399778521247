import "bootstrap/dist/css/bootstrap.min.css";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import React, { useEffect } from "react";
import { initReactI18next } from "react-i18next";
import { useHistory } from "react-router";
import { AuthProvider } from "./contexts/AuthContext";
import Routes from "./routes/Router";
import { setupResponseInterceptor } from "./services/api";
import GlobalStyle from "./style/GlobalStyle";
import { ModalProvider } from "./contexts/ModalContext";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init(
    {
      supportedLngs: ["en", "pt"],
      fallbackLng: "pt",
      ns: ["basic"],
      defaultNS: "basic",
      keySeparator: false, // we use content as keys
      detection: {
        order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
        caches: ["cookie"],
      },
      backend: {
        loadPath: "/assets/languages/{{lng}}/translation.json",
      },
      react: { useSuspense: false },
    },
    () => {
      // console.log("i18n lang => ", i18n.language);
    },
  );

const App: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    setupResponseInterceptor(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthProvider>
      <GlobalStyle />
      <ModalProvider>
        <Routes />
      </ModalProvider>
    </AuthProvider>
  );
};

export default App;
