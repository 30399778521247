import styled from "styled-components";
import GlobalColors from "../../../config/global-colors";

export const NavBar = styled.div`
    background-color: white;
    height: 100%;
    width: 5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 1px;
    top: 1px;
    flex-direction: column;
    box-shadow: inset -3px -3px 4px rgba(0, 0, 0, 0.25);
`;

export const CloseSideBar = styled.div`
    position: relative;
    left: 1.5rem;
    bottom: 1rem;
`;

export const IconContainer = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
        transform: scale(1.1);
        transition: 0.5s;
    }
`;

export const Header = styled.div`
  height: 9rem;
  padding: 2rem 0 4rem 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const HeaderImg = styled.img`
  height: 3rem;
`;

export const SideBar = styled.div`
    background-color: white;
    height: 100%;
    width: 16rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    bottom: 1px;
    top: 1px;
    flex-direction: column;
    padding-left: 2rem;
    box-shadow: inset -3px -3px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
`;

export const NavIconContainer = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    :hover {
        transform: scale(1.1);
        transition: 0.5s;
    }
`;

export const NavHeader = styled.div`
  height: 7.5rem;
  width: 12rem;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2rem;
  border-bottom: solid grey;
  border-width: thin;
`;

export const IconLabel = styled.div`
    margin-left: 2rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${GlobalColors.primary};
`;

export const HeaderDesc = styled.img`
  height: 3rem;
  margin-left: 1rem;
  margin-top: 0.3rem;
`;

export const DividerTitle = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: ${GlobalColors.primary};
    position: relative;
    top: -13px;
    left: -10px;
    background-color: white;
    width: 4rem;
`;

export const Container = styled.div`
    width: 100%;
    margin-top: 1rem;
`;