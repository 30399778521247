import React from 'react';
import { IconContext } from 'react-icons';
import { SidebarData } from './sideBarData';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';
import {
    Container,
    DividerTitle,
    Header,
    HeaderDesc,
    HeaderImg,
    IconContainer,
    IconLabel,
    NavBar,
    NavHeader,
    NavIconContainer,
    SideBar,
    CloseSideBar
} from './styles';
import * as AiIcons from "react-icons/ai";
import logoDesc from "../../../assets/images/logo-blue-desc.png";
import logo from "../../../assets/images/logo-hex.png";
import GlobalColors from "../../../config/global-colors";

interface SidenbarProps {
    isSidebarOpen: boolean;
    setIsSidebarOpen(value: boolean): void;
}

const Sidebar: React.FC<SidenbarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <IconContext.Provider value={{ color: `${GlobalColors.primary}`, size: '1.5rem' }}>
            {!isSidebarOpen ?
                <NavBar className='d-none d-sm-block'>
                    <Header>
                        <HeaderImg src={logo} onClick={() => history.push('/home')}/>
                    </Header>
                    {SidebarData.map((item, index) => 
                        <React.Fragment key={index}>
                            <IconContainer key={item.title} onClick={() => history.push(item.path)} id={item.cName}>
                                {item.icon}
                            </IconContainer>
                            <UncontrolledTooltip
                                placement="bottom"
                                target={item.cName}
                            >
                                {t(item.cName)}
                            </UncontrolledTooltip>
                        </React.Fragment> 
                    )}
                </NavBar>
                :
                <SideBar>
                    <NavHeader>
                        <HeaderImg src={logo}  onClick={() => history.push('/home')}/>
                        <HeaderDesc src={logoDesc}  onClick={() => history.push('/home')}/>
                        <CloseSideBar onClick={() => setIsSidebarOpen(false)}><AiIcons.AiFillCaretLeft color={`${GlobalColors.primary}`}/></CloseSideBar>
                    </NavHeader>
                    <DividerTitle>
                        {t("general")}
                    </DividerTitle>
                    <Container>
                        {SidebarData.map((item) => {
                            return (
                                <NavIconContainer key={item.title} onClick={() => history.push(item.path)}>
                                    {item.icon}
                                    <IconLabel>
                                        {t(item.title)}
                                    </IconLabel>
                                </NavIconContainer>
                            );
                        })}
                    </Container>
                </SideBar>
            }
        </IconContext.Provider>
    );
};

export default Sidebar;