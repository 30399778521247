import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useModal } from "../../contexts/ModalContext";
import Loading from "../loader";

const GenericModal = () => {
    const { isOpen, modalContent, modalOptions, isModalLoading: isLoading, hideModal } = useModal();

    const handleOkClick = () => {
        if (modalOptions.onConfirmButton) {
            modalOptions.onConfirmButton();
        }

        if (!modalOptions.shouldNotHideAferConfirm) {
            hideModal();
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={hideModal}>
            <ModalHeader toggle={hideModal}>{modalOptions.title}</ModalHeader>
            <ModalBody>{modalContent}</ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={isLoading} onClick={handleOkClick}>
                    {!isLoading ? modalOptions.btnOkLabel || "OK" : <Loading size={"1.2rem"} />}
                </Button>
                <Button color="secondary" onClick={hideModal}>
                    {modalOptions.btnCancelLabel || "Cancel"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default GenericModal;
