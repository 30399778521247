import { createGlobalStyle } from "styled-components";
// import Colors from "../config/global-colors";

const GlobalStyle = createGlobalStyle`
    html, body, #root, #app, #app>div {
      height: 100%
    }
    * {
        /* margin: 0; */
        /* padding: 0; */
        /* box-sizing: border-box; */
        font-family: 'Poppins', sans-serif;
        /* font-size: 16px; */
    }

    body {
	    line-height: 1.5rem;
        overflow: hidden;
    }

    ol, ul {
	    list-style: none;
    }

    blockquote, q {
	    quotes: none;
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Roboto', sans-serif;
    }

    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2rem;
    }

    h4 {
        font-size: 1.8rem;
    }

    h5 {
        font-size: 1.5rem;
    }

    h6 {
        font-size: 1.2rem;
    }

`;

export default GlobalStyle;
